<template>
  <section>
    <e-filters
      :title="$t('Filtros')"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="6">
          <FormulateInput
            v-model="filters.name"
            name="name"
            :label="$t('Nome do Perfil')"
            :placeholder="$t('Nome')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="role-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('ACCESS_PROFILE.NAME'))"
        :fields="fields"
        :items="accessProfiles"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="true"
            :show-delete="false"
            @update="updateRole(row)"
          />
        </template>

        <template #cell(headProfile)="row">
          <e-status-badge
            :status="row.item.headProfile"
            type="yesNo"
          />
        </template>

        <template #cell(directorProfile)="row">
          <e-status-badge
            :status="row.item.directorProfile"
            type="yesNo"
          />
        </template>
        <template #cell(directorCommercialProfile)="row">
          <e-status-badge
            :status="row.item.directorCommercialProfile"
            type="yesNo"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="role-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <FAB
      :main-tooltip="$t('Adicionar Perfil de Acesso')"
      @click="create"
    />
  </section>
</template>

<script>
import { BTable, BPagination, BRow, BCol } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import {
  FAB,
  EPaginationSummary,
  EPageSizeSelector,
  EGridActions,
  EFilters,
  EStatusBadge,
} from '@/views/components'
import { stringUtils } from '@/mixins'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    EPaginationSummary,
    EPageSizeSelector,
    BCardActions,
    FAB,
    EGridActions,
    EFilters,
    EStatusBadge,
  },
  directives: { Ripple },

  mixins: [stringUtils],

  data() {
    return {
      loaded: false,
      fetching: false,
      options: [{ value: '', label: this.$t('Selecione uma empresa') }],
    }
  },

  computed: {
    ...mapState('pages/security/accessProfiles', ['paging', 'accessProfiles', 'filters']),

    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Head?'),
          key: 'headProfile',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Diretor?'),
          key: 'directorProfile',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Diretor Comercial?'),
          key: 'directorCommercialProfile',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
      ]
    },
  },

  mounted() {
    if (!this.accessProfiles.length) {
      this.getData()
    }
    setTimeout(() => {
      this.loaded = true
    }, 500)
  },

  methods: {
    ...mapActions('pages/security/accessProfiles', [
      'fetchAccessProfiles',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      try {
        this.fetching = true
        await this.fetchAccessProfiles()
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    create() {
      this.$router.push({
        name: 'role-add',
      })
    },
    updateRole(role) {
      this.$router.push({
        name: 'role-maintain',
        params: { id: role.item.id },
      })
    },
  },
}
</script>
